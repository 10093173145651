import { MoveRight } from 'lucide-react';
import { useResetForm, useResetPassword } from './hooks';
import { useParams } from 'react-router-dom';
import InputError from '../elements/InputError';
import { useState } from 'react';
import { getWebsiteUrl } from '../utils';


const ResetPasswordForm = () => {
  const { token, accountId } = useParams();
  const { resetPassword } = useResetPassword();
  const [resetted, setResetted] = useState(false);

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useResetForm({
    password: '',
    repeatPassword: ''
  });

  const doResetPassword = async () => {
    try {
      await resetPassword(
        getValues(),
        token,
        accountId
      );
      setResetted(true);

      setTimeout(() => {
        window.location.href = getWebsiteUrl();
      }, 3000);
    } catch (error) {
      window.location.reload();
    }
  };

  return (
    <form onSubmit={handleSubmit(doResetPassword)}>
      {
        resetted && (
          <div>
            <p>Je wachtwoord is succesvol aangepast. We verwijzen je door naar onze website.</p>
          </div>
        )
      }

      {
        !resetted && (
          <>
            <div className="mb-4 w-full rounded-lg">
              <label htmlFor="email" className="block">
                Nieuw wachtwoord
              </label>
              <input
                {...register('password')}
                type="password"
                placeholder="********"
                className="w-full p-4 mt-4 rounded-lg bg-primary"
              />

              {errors.password && <InputError text={errors.password.message} />}
            </div>
            <div className="mb-4 w-full rounded-lg">
              <label htmlFor="email" className="block">
                Herhaal wachtwoord
              </label>
              <input
                {...register('repeatPassword')}
                type="password"
                placeholder="********"
                className="w-full p-4 mt-4 rounded-lg bg-primary"
              />

              {errors.repeatPassword && <InputError text={errors.repeatPassword.message} />}
            </div>
            <button
              type="submit"
              className="bg-accent w-full rounded-lg p-4 text-primary mb-4 flex gap-8 justify-center"
            >
              Herstel wachtwoord
              <MoveRight />
            </button>
          </>
        )
      }
    </form>
  );
};

export default ResetPasswordForm;
